<template>
    <div class="zip-widget-modal-component" v-if="zipPluginLoaded">
        <a href="#" @click.prevent="onClickOpenZipModal">
            <img
                :src="formatAssetUrl('images/question.svg')"
                v-if="zipModalId"
                alt="question mark icon"
            />
        </a>
        <div class="zip-widget-plugin-wrapper">
            <div id="zipWidgetModalAmount"></div>
            <quadpay-widget-v3
                :merchantId="zipMerchantId"
                amountSelector="#zipWidgetModalAmount"
                :amount="amount"
            ></quadpay-widget-v3>
        </div>
    </div>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageUrlMixin],
    props: {
        amount: {
            type: Number,
            default: 0
        }
    },
    mounted() {
        this.widgetCheckInterval = window.setInterval(() => {
            if (typeof window.quadpay !== 'undefined') {
                this.zipPluginLoaded = true;
            }

            if (this.zipPluginLoaded && this.populateZipModalId()) {
                window.clearInterval(this.widgetCheckInterval);
            }

            this.checkAttempts++;

            if (this.checkAttempts > 10) {
                window.clearInterval(this.widgetCheckInterval);
            }
        }, 1000);
    },
    destroyed() {
        if (this.widgetCheckInterval) {
            window.clearInterval(this.widgetCheckInterval);
        }
    },
    data() {
        return {
            zipPluginLoaded: false,
            widgetCheckInterval: null,
            zipModalId: null,
            checkAttempts: 0
        }
    },
    computed: {
        zipMerchantId() {
            return this.$config.zipMerchantId;
        }
    },
    methods: {
        onClickOpenZipModal() {
            if (!this.zipModalId) {
                return;
            }

            try {
                window.quadpay.widget.displayModal(this.zipModalId);
            } catch (e) {
                // Prevent issues showing modal
            }
        },
        populateZipModalId() {
            if (typeof document.getElementsByTagName('quadpay-modal-base') === 'undefined') {
                return null;
            }

            if (document.getElementsByTagName('quadpay-modal-base').length <= 0) {
                return null;
            }

            const zipModalElement = document.getElementsByTagName('quadpay-modal-base')[0];

            try {
                this.zipModalId = zipModalElement.id.replace('qp-modal-', '');
                return this.zipModalId;
            } catch (e) {
                return null;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.zip-widget-modal-component {
    display: inline-block;
    margin-left: 5px;
    margin-top: 2px;
    vertical-align: top;
    a {
        display: inline-block;
        vertical-align: top;
    }
    img {
        display: inline-block;
        vertical-align: top;
        width: 14px;
    }
    .zip-widget-plugin-wrapper {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        width: 1px;
        height: 1px;
        top: 0px;
        left: 0px;
    }
}
</style>
