<template>
    <!-- Start Footer Area -->
    <footer>
        <div class="checkout-navbar checkout-footer">
            <div class="row border-bottom pb-3">
                <div class="col-lg-7 col-md-12">
                    <div class="pb-2 hidden-mobile" v-if="cart"><strong>Ref# {{ cart.id }}</strong></div>
                    <div class="mt-2 footer-text">
                        For contact lens orders, you may return your order in its original condition within 90 days of
                        purchase.
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 text-center text-lg-right inline-links-section">
                    <img :src="formatAssetUrl('images/encryption.png')" alt="encryption" class="mx-3" />
                    <a href="https://www.trustedsite.com/verify?host=lensdirect.com" target="_blank" rel="noopener">
                        <img
                            src="https://cdn.ywxi.net/static/img/banner/trustedsite-certified-secure.png"
                            alt="TrustedSite Certified"
                            title="TrustedSite Certified"
                            border="0"
                            width="110"
                            height="45"
                            class="mx-3"
                        />
                    </a>
                </div>
            </div>
            <div class="col-12">
                <div class="text-secondary mt-3 footer-text">
                    © {{ currentYear }} LensDirect.com - 401 Franklin Avenue, Garden City, NY 11530 USA
                </div>
            </div>
        </div>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    name: 'CheckoutFooter',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        cart() {
            return this.$store.getters.cart;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.footer-text {
    font-size: 16px;
}
@media (max-width: $breakpoint5) {
    .footer-text {
        font-size: 14px !important;
        text-align: center;
    }
    .checkout-navbar.checkout-footer {
        background-color: #f4f7f9;
    }
}
</style>
