<template>
    <form class="login-form-container" @submit.prevent="onSubmit">
        <h1>
            <slot name="title">Welcome back!</slot>
        </h1>
        <div class="alert alert-danger w-full" v-if="error.message">
            {{ error.message }}
        </div>

        <CustomInput
            class="w-full"
            label="Email Address"
            type="email"
            :errorMessage="error.emailAddress"
            v-model="emailAddress"
            @ref="emailInputRef = $event"
            @input="error.emailAddress = ''"
        />
        <CustomInput
            class="w-full"
            label="Password"
            type="password"
            autocomplete="off"
            :errorMessage="error.password"
            v-model="password"
            @ref="passwordInputRef = $event"
            @input="error.password = ''"
        />
        <CustomButton
            class="btn btn-outline outline-gray w-full"
            type="submit"
            :isLoading="isLoading"
        >
            Sign in
        </CustomButton>
        <CustomButton
            class="btn btn-link btn-forgot-password"
            type="button"
            @click="onClickForgotPassword"
        >
            Forgot password?
        </CustomButton>
        <OrSeparator>
            Or sign in with
        </OrSeparator>
        <FacebookButton
            :redirectPath="redirectPath"
            @loggedIn="onFacebookLogin"
            @error="onFacebookError"
        />
        <AmazonLoginButton
            :redirectPath="redirectPath"
        />

        <div class="create-account-link-container">
            New to LensDirect? <a @click.prevent="onClickCreateAccount">Create an account</a>
        </div>
    </form>
</template>

<script>
import FacebookButton from '@/components/auth-form/FacebookButton';
import AmazonLoginButton from '@/components/auth-form/AmazonLoginButton';
import OrSeparator from '@/components/OrSeparator';
import notificationsMixin from '@/mixins/notifications';

export default {
    mixins: [notificationsMixin],
    components: {
        AmazonLoginButton,
        FacebookButton,
        OrSeparator
    },
    props: {
        redirectPath: {
            type: String,
            default: '/profile/account-snapshot'
        }
    },
    mounted() {
        if (this.$store.state.auth.authFormEmailAddress) {
            this.emailAddress = this.$store.state.auth.authFormEmailAddress;
        }

        this.initFocus();
    },
    data() {
        return {
            emailInputRef: null,
            passwordInputRef: null,
            emailAddress: '',
            password: '',
            error: {
                message: '',
                emailAddress: '',
                password: '',
                facebook: '',
                amazon: ''
            },
            isLoading: false
        };
    },
    methods: {
        onFacebookError(e) {
            let defaultError = 'Unable to login with Facebook at this time.  ' +
                'Please try logging in using your email address.';
            this.processError(e, defaultError);
        },
        validate() {
            let isValid = true;

            if (!this.emailAddress) {
                this.error.emailAddress = 'Email address is required.';
                isValid = false;
            }

            if (!this.password) {
                this.error.password = 'Password is required.';
                isValid = false;
            }

            return isValid;
        },
        initFocus() {
            if (this.emailAddress && this.passwordInputRef) {
                this.passwordInputRef.focus();
                return;
            }

            if (this.emailInputRef) {
                this.emailInputRef.focus();
            }
        },
        async getCaptchaToken() {
            try {
                let token = await this.$recaptcha.execute('login');
                return token;
            } catch (e) {
                // Prevent issues getting captcha token
            }

            return '';
        },
        async onSubmit() {
            this.error = {
                message: '',
                emailAddress: '',
                password: '',
                facebook: '',
                amazon: ''
            };

            if (!this.validate()) {
                return;
            }

            this.isLoading = true;
            let payload = {
                username: this.emailAddress,
                password: this.password
            };

            let token = await this.getCaptchaToken();
            payload.token = token;

            try {
                await this.$store.dispatch('auth/login', payload);
                await this.$store.dispatch('initCart');
                this.$emit('fetchingUser');
                await this.$store.dispatch('auth/getUser');
                this.$emit('loggedIn');
            } catch (e) {
                this.error.message = e;
            }

            this.isLoading = false;
        },
        onClickCreateAccount() {
            this.$store.dispatch('auth/setAuthFormEmail', this.emailAddress);
            this.$emit('createAccount');
        },
        onClickForgotPassword() {
            this.$store.dispatch('auth/setAuthFormEmail', this.emailAddress);
            this.$emit('forgotPassword', this.emailAddress);
        },
        onFacebookLogin() {
            this.$emit('loggedIn');
            this.$emit('fbLoggedIn');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/color.scss';

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%;
    gap: 10px;

    h1 {
        font-family: $font-primary;
        font-weight: 900;
        font-size: 32px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        line-height: 1.2em;
    }

    .btn-outline {
        border-width: 1px;
        min-height: 57px;
    }

    .btn-link {
        display: inline-block;
        width: auto;
        font-size: 16px;
        color: #000000;
        text-decoration: underline;
        &:hover {
            color: $color-blue;
        }
        &.btn-forgot-password {
            padding-top: 5px;
            padding-bottom: 0px;
        }
    }

    .create-account-link-container {
        margin-top: 25px;
        font-size: 16px;
        a {
            display: inline;
            font-weight: bold;
            color: $color-blue;
            text-decoration: underline;
            &:hover {
                color: $bg-blue-hover;
                text-decoration: underline;
            }
        }
    }

    ::v-deep {
        .form-label-group {
            margin-bottom: 5px;
        }
        .or-separator-container {
            .or-separator-text {
                width: 120px;
                font-size: 13px;
                margin-left: -60px;
            }
        }
    }
}
</style>
