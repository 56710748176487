<template>
    <form class="login-form-container" @submit.prevent="onSubmit">
        <h1>
            Nice to meet you!
            <br>
            <small>
                Gain access to your saved prescriptions, addresses, and order status
            </small>
        </h1>
        <div class="alert alert-danger w-full" v-if="error.message || error.message.length > 0">
            <div v-if="error.message" :class="{ bold: error.messages.length > 0 }">
                {{ error.message }}
            </div>
            <ul v-if="error.messages.length > 0">
                <li v-for="(errorMessage, index) in error.messages" :key="index">
                    {{ errorMessage }}
                </li>
            </ul>
        </div>
        <CustomInput
            class="w-full"
            label="First Name"
            type="text"
            :errorMessage="error.firstName"
            v-model="firstName"
            @input="error.firstName = ''"
        />
        <CustomInput
            class="w-full"
            label="Last Name"
            type="text"
            :errorMessage="error.lastName"
            v-model="lastName"
            @input="error.lastName = ''"
        />
        <CustomInput
            class="w-full"
            label="Email Address"
            type="email"
            :errorMessage="error.emailAddress"
            v-model="emailAddress"
            @ref="emailInputRef = $event"
            @input="error.emailAddress = ''"
        />
        <div class="parent position-relative w-full">
            <CustomInput
                class="w-full"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                :errorMessage="error.password"
                v-model="password"
                autocomplete="off"
                @ref="passwordInputRef = $event"
                @input="error.password = ''"
            />
            <div class="position-absolute view-password">
                <font-awesome-icon
                    @click="showPassword = !showPassword"
                    :icon="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                    :aria-label="showPassword ? 'Hide Password' : 'Show Password'"
                    :alt="showPassword ? 'Hide Password' : 'Show Password'"
                    class="text-grey p-2"
                />
            </div>
        </div>
        <div class="parent position-relative w-full">
            <CustomInput
                class="w-full"
                label="Confirm Password"
                :type="showConfirmPassword ? 'text' : 'password'"
                :errorMessage="error.confirmPassword"
                v-model="confirmPassword"
                autocomplete="off"
                @input="error.confirmPassword = ''"
            />
            <div class="position-absolute view-password">
                <font-awesome-icon
                    @click="showConfirmPassword = !showConfirmPassword"
                    :icon="showConfirmPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                    class="text-grey p-2"
                    :aria-label="showConfirmPassword ? 'Hide Password' : 'Show Password'"
                    :alt="showConfirmPassword ? 'Hide Password' : 'Show Password'"
                />
            </div>
        </div>
        <CustomButton class="btn btn-outline w-full outline-gray" type="submit" :isLoading="isLoading">
            Create my account
        </CustomButton>
        <div class="create-account-link-container">
            Already a customer? <a @click.prevent="onClickSignIn">Sign In</a>
        </div>
    </form>
</template>

<script>
export default {
    async mounted() {
        if (this.$store.state.auth.authFormEmailAddress) {
            this.emailAddress = this.$store.state.auth.authFormEmailAddress;
            this.$store.dispatch('auth/setAuthFormEmail', '');
        }

        this.initFocus();

        try {
            await this.$recaptcha.init();
        } catch (e) {
            // Prevent issues loading the captcha
            console.error(e);
        }
    },
    beforeDestroy() {
        try {
            this.$recaptcha.destroy();
        } catch (e) {
            // Prevent issues destroying the captcha
        }
    },
    data() {
        return {
            showPassword: false,
            showConfirmPassword: false,
            emailInputRef: null,
            passwordInputRef: null,
            emailAddress: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            error: {
                message: '',
                messages: [],
                emailAddress: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: ''
            },
            isLoading: false
        };
    },
    methods: {
        initFocus() {
            if (this.emailAddress && this.passwordInputRef) {
                this.passwordInputRef.focus();
                return;
            }

            if (this.emailInputRef) {
                this.emailInputRef.focus();
            }
        },
        validate() {
            let isValid = true;
            if (!this.emailAddress) {
                this.error.emailAddress = 'Email address is required.';
                isValid = false;
            }

            if (!this.password) {
                this.error.password = 'Password is required.';
                isValid = false;
            }

            if (!this.firstName) {
                this.error.firstName = 'First name is required.';
                isValid = false;
            }

            if (!this.lastName) {
                this.error.lastName = 'Last name is required.';
                isValid = false;
            }

            if (this.password !== this.confirmPassword) {
                this.error.confirmPassword = 'Password confirmation does not match.';
                isValid = false;
            }

            return isValid;
        },
        async onSubmit() {
            this.errors = {
                message: '',
                messages: [],
                emailAddress: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: ''
            };

            if (!this.validate()) {
                return;
            }

            let payload = {
                email: this.emailAddress,
                password: this.password,
                confirmPassword: this.confirmPassword,
                firstName: this.firstName,
                lastName: this.lastName
            };

            this.isLoading = true;
            try {
                await this.$store.dispatch('auth/signUp', payload);
                let token = await this.$recaptcha.execute('login');

                await this.$store.dispatch('auth/login', {
                    username: this.emailAddress,
                    password: this.password,
                    token: token
                });

                this.$emit('accountCreated');
            } catch (e) {
                console.log(e);
                this.populateErrors(e);
            }
            this.isLoading = false;
        },
        populateErrors(e) {
            if (e?.message) {
                this.error.message = e.message;
            }

            if (e?.errors?.length > 0) {
                this.error.messages = e.errors;
            }
        },
        onClickSignIn() {
            this.$store.dispatch('auth/setAuthFormEmail', this.emailAddress);
            this.$emit('signIn');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/color.scss';

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%;
    gap: 10px;

    h1 {
        font-family: $font-primary;
        font-weight: 900;
        font-size: 32px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        line-height: 1.2em;
        text-align: center;
        small {
            display: block;
            font-size: 18px;
            line-height: 1.2em;
            padding: 10px 0px;
        }
    }

    .btn-outline {
        border-width: 1px;
        min-height: 57px;
    }

    .btn-link {
        font-size: 16px;
        color: #000000;
        &:hover {
            color: $color-blue;
        }
    }

    .create-account-link-container {
        margin-top: 25px;
        font-size: 16px;
        a {
            display: inline;
            font-weight: bold;
            color: $color-blue;
            &:hover {
                color: $bg-blue-hover;
                text-decoration: underline;
            }
        }
    }

    ::v-deep {
        .form-label-group {
            margin-bottom: 5px;
        }
    }
    .view-password {
        right: 6px;
        bottom: 12px;
    }
}
</style>
