<template>
    <AppWrapper :hideChatMobile="true" class="choose-lenses--version2">
        <DesktopHeader :isLoading="!flowTree || !baseProduct" />
        <MobileHeader :baseProduct="baseProduct" v-if="flowTree && baseProduct" />
        <MobileProgress v-if="flowTree && baseProduct" />
        <div class="row">
            <div class="selection-summary-container hidden-mobile">
                <ProductPreview
                    :isForLensReplacement="false"
                    :couponCode="couponCode"
                />
            </div>
            <div class="selection-choices-container" v-if="flowTree" :class="{ 'has-next-button': usesNextButton }">
                <client-only>
                    <nuxt />
                </client-only>

            </div>
            <div class="selection-choices-container" v-else>
                <LoadingIndicator />
            </div>
        </div>
        <div v-if="!usesNextButton" class="hidden-desktop choose-lenses-mobile-summary">
            <MobileSummary v-if="flowTree && baseProduct" />
        </div>
    </AppWrapper>
</template>

<script>
const nextButtonRoute = [
    'choose-lenses-slug-lens-coating',
    'choose-lenses-slug-lens-type',
    'choose-lenses-slug-enter-rx',
    'choose-lenses-slug-lens-power',
    'choose-lenses-slug-lens-material'
];

import AppWrapper from '@/components/AppWrapper';
import Header from '@/layouts/Header';
import DesktopHeader from '@/components/landing-page/lens-replacement/steps-v2/DesktopHeader';
import LoadingIndicator from '@/components/LoadingIndicator';
import ProductPreview from '@/components/landing-page/choose-lenses/steps-v2/ProductPreview';
import MobileProgress from '@/components/landing-page/choose-lenses/steps-v2/MobileProgress';
import MobileHeader from '@/components/landing-page/choose-lenses/steps-v2/MobileHeader';
import MobileSummary from '@/components/landing-page/choose-lenses/steps-v2/MobileSummary';

import templateSetupMixin from '@/mixins/eyewear-flow-v2/template-setup-mixin';

import loadProduct from '@/utils/eyewear-flow-v2/load-product';
import productTypes from '@/constants/product-types';
import loadZipPayWidgetJs from '@/third-party/zippay';

export default {
    mixins: [templateSetupMixin],
    components: {
        AppWrapper,
        Header,
        DesktopHeader,
        LoadingIndicator,
        ProductPreview,
        MobileProgress,
        MobileHeader,
        MobileSummary
    },
    async mounted() {
        if (!this.baseProduct) {
            await this.loadProduct();
        }

        this.$store.dispatch('eyewearFlowV2/setPreviousSelections', []);
        this.$store.dispatch('eyewearFlow/resetStorage');

        this.loadedNextButtonRoute = nextButtonRoute.indexOf(this.$route.name) !== -1;

        loadZipPayWidgetJs(this.$config.zipWidgetJsUrl);

        await this.loadFlowData();
    },
    data() {
        return {
            loadedNextButtonRoute: false
        }
    },
    computed: {
        usesNextButton() {
            return nextButtonRoute.indexOf(this.$route.name) !== -1 && this.loadedNextButtonRoute;
        },
        routePath() {
            return this.$route.name;
        },
        couponCode() {
            if (!this.$store.state.eyewearFlow.baseProduct) {
                return '';
            }

            return this.$store.state.eyewearFlow.baseProduct.sunglasses
                ? 'SUN20'
                : 'EYE20';
        }
    },
    methods: {
        async loadProduct() {
            const eyewearProductTypes = [
                productTypes.GLASSES.id,
                productTypes.SUNGLASSES.id
            ];

            try {
                await loadProduct(
                    this.$store,
                    this.$axios,
                    this.$route.params.slug,
                    eyewearProductTypes,
                    'Those glasses aren\'t available!  Please select a different frame.'
                );
            } catch (e) {
                console.error(e);
            }
        }
    },
    watch: {
        $route(newRoute, oldRoute) {
            if (typeof window === 'undefined') {
                return;
            }

            // Slightly delaying having the has-next-button class applied to prevent
            // page from stretching before the next page is loaded
            if (nextButtonRoute.indexOf(newRoute.name) !== -1) {
                window.setTimeout(() => {
                    this.loadedNextButtonRoute = true;
                }, 10);
                return;
            }

            this.loadedNextButtonRoute = false;
        }
    }
}
</script>

<style lang="scss">
@import '~/assets/scss/components/landing-page/eyewear-flow.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.choose-lenses--version2 {
    .selection-choices-container {
        .glasses-step-container--version2 {
            font-size: 16px;
            width: 77%;
            min-width: 700px;
            .back-button-wrapper {
                padding-bottom: 20px;
                .btn-back-button {
                    color: #91A5B4;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .step-title-heading {
                font-size: 2em;
                line-height: 1.2em;
                margin-bottom: 20px;
            }
            .step-choices-wrapper {
                display: flex;
                gap: 16px;
                flex-direction: column;
                .step-choice-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    background-color: #ffffff;
                    border: solid #DCE3E8 1px;
                    border-radius: 10px;
                    padding: 20px 30px;
                    text-align: left;
                    min-height: 103px;
                    .step-choice-image-wrapper {
                        img {
                            max-width: calc(1vw * (140 / 19.2));
                            mix-blend-mode: darken;
                        }
                    }
                    .step-choice-content-wrapper {
                        width: 100%;
                        .promo-text {
                            display: inline-block;
                            color: #000000;
                            border-radius: 100px;
                            font-size: 12px;
                            line-height: 12px;
                            height: auto;
                            padding: 5px 10px;
                            background-color: #fbec8e;
                            font-weight: 600;
                            vertical-align: top;
                            margin-top: 5px;
                            &.hidden-desktop {
                                display: none;
                            }
                        }
                    }
                    .step-choice-title-wrapper {
                        display: flex;
                        gap: 10px;
                        h4 {
                            display: inline-block;
                            font-size: 1.5em;
                            line-height: 1.1em;
                            margin-bottom: 0px;
                        }
                        .price-container {
                            display: inline-block;
                            margin-left: auto;
                            font-size: .875em;
                            &.free {
                                color: #CB1F2B;
                                font-weight: bold;
                            }
                        }
                    }
                    .step-choice-description-container {
                        padding-top: 10px;
                    }
                    &.has-radio-options {
                        flex-direction: column;
                        padding: 0px;
                        .step-choice-content-wrapper {
                            padding: 20px 24px;
                        }
                        .radio-option-name-wrapper {
                            display: flex;
                            font-size: 1.28571em;
                            font-weight: bold;
                            .price-container {
                                margin-left: auto;
                                font-size: .777778em;
                                font-weight: normal;
                                &.free {
                                    color: #CB1F2B;
                                    font-weight: bold;
                                }
                            }
                        }
                        .radio-option-description {
                            width: 90%;
                        }
                        .step-choice-description-container {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                        }
                        .custom-radio-component {
                            align-items: center;
                            gap: 15px;
                            border-top: solid #DCE3E8 1px;
                            padding-top: 15px;
                            &:first-child {
                                border-top: 0px;
                                padding-top: 0px;
                            }
                            .radio-container {
                                width: 30px;
                                height: 30px;
                            }
                            .radio-label {
                                width: 100%;
                            }
                            &:hover {
                                .radio-label {
                                    text-decoration: none !important;
                                }
                            }
                            &.active {
                                font-weight: normal;
                                .radio-container {
                                    background-color: #0F67EB;

                                    border-color: #0F67EB;
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                        .show-detail-button-wrapper {
                            width: 100%;
                            border-bottom-right-radius: 10px;
                            border-bottom-left-radius: 10px;
                            padding: 20px;
                            text-align: center;
                            background-color: #F1F8FF;
                            button {
                                background-color: transparent;
                                border: 0px;
                                color: #78A4E7;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                button {
                    &.step-choice-container {
                        &:hover {
                            border-color: #91A5B4;
                        }
                        &.active {
                            background-color: #E2EEFF;
                            border-color: #78A4E7;
                        }
                    }

                }
            }
        }
        .next-button-wrapper {
            display: none;
        }
        &.has-next-button {
            padding: 0px !important;
            .glasses-step-container--version2 {
                padding: 100px 20px 0px 95px;
                height: 100vh;
                overflow: auto;
                width: 100%;
                .glasses-step-inner-container {
                    width: 77%;
                    min-width: 700px;
                    padding-bottom: 120px;
                }
            }
            .next-button-wrapper {
                position: absolute;
                display: block;
                bottom: 0px;
                left: 0px;
                width: 100%;
                padding: 20px;
                padding-left: 95px;
                background-color: #F1F8FF;
                box-shadow: 0px -1px 24px #DCE3E86E;
                &.hidden-desktop {
                    display: none;
                }
                .btn-cta {
                    &:not(.btn-add-to-cart) {
                        display: inline-block;
                        width: auto;
                        height: 56px;
                        font-weight: bold;
                        background: transparent linear-gradient(180deg, #78A4E7 0%, #588CDC 100%) 0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 2px 2px #ADC9F3, 0px 2px 5px #00000026;
                        text-shadow: 0px 1px 1px #2256A4;
                        min-width: 250px;
                        &:hover {

                            background: transparent linear-gradient(180deg, #588CDC 0%, #78A4E7 100%) 0% 0% no-repeat padding-box !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    .choose-lenses--version2 {
        .selection-choices-container {
            .glasses-step-container--version2 {
                width: 85%;
                min-width: 600px;
            }
            &.has-next-button {
                .glasses-step-container--version2 {
                    .glasses-step-inner-container {
                        width: 85%;
                        min-width: 600px;
                    }
                }
            }
        }
        .glasses-flow-header {
            font-size: 16px;
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    .choose-lenses--version2 {
        .selection-choices-container {
            .glasses-step-container--version2 {
                width: 89%;
            }
            &.has-next-button {
                .glasses-step-container--version2 {
                    .glasses-step-inner-container {
                        width: 89%;
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .choose-lenses--version2 {
        .selection-choices-container {
            .glasses-step-container--version2 {
                width: 95%;
                min-width: auto;
                .step-choices-wrapper {
                    .step-choice-container {
                        .step-choice-title-wrapper {
                            align-items: center;
                            h4 {
                                font-size: 1.3em;
                            }
                        }
                        .step-choice-image-wrapper {
                            img {
                                max-width: 120px;
                            }
                        }
                    }
                }
            }
            &.has-next-button {
                .glasses-step-container--version2 {
                    padding-left: 70px;
                    .glasses-step-inner-container {
                        width: 95%;
                        min-width: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    .choose-lenses--version2 {
        .selection-choices-container {
            .glasses-step-container--version2 {
                .step-choices-wrapper {
                    .step-choice-container {
                        .step-choice-title-wrapper {
                            h4 {
                                font-size: 1.1em;
                            }
                        }
                        .step-choice-image-wrapper {
                            img {
                                max-width: calc(1vw * (100 / 12.8));
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .choose-lenses--version2 {
        .selection-choices-container {
            .glasses-step-container--version2 {
                width: 100%;
                .back-button-wrapper {
                    padding-bottom: 15px;
                    .btn-back-button {
                        color: #91A5B4 !important;
                        padding: 5px 10px;
                        border-radius: 100px;
                        font-size: .875em;
                        gap: 5px;
                        img {
                            &:first-child {
                                display: inline-block !important;
                            }
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .step-title-heading {
                    font-size: 1.25em;
                    margin-bottom: 0px;
                }
                .step-choices-wrapper {
                    padding-top: 20px;
                    gap: 10px;
                    .step-choice-container {
                        padding: 20px 24px;
                        gap: 10px;
                        &.has-radio-options {
                            .step-choice-description-container {
                                padding-top: 0px;
                            }
                            .radio-option-description {
                                display: none;
                            }
                            &.detail-visible {
                                .radio-option-description {
                                    display: block;
                                }
                            }
                        }
                        .step-choice-title-wrapper {
                            h4 {
                                font-size: 1.125em;
                            }
                            .promo-text {
                                display: none;
                            }
                        }
                        .step-choice-description-container {
                            font-size: .875em;
                        }
                        .step-choice-image-wrapper {
                            img {
                                max-width: 70px;
                            }
                        }
                        .step-choice-content-wrapper {
                            .promo-text {
                                &.hidden-desktop {
                                    display: inline-block !important;
                                }
                            }
                        }
                    }
                }
            }
            &.has-next-button {
                .glasses-step-container--version2 {
                    .glasses-step-inner-container {
                        width: 100%;
                    }
                }
            }
            &.has-next-button {
                .glasses-step-container--version2 {
                    padding: 20px 15px;
                    height: auto;
                    .glasses-step-inner-container {
                        padding-bottom: 150px;
                    }
                }
                .next-button-wrapper {
                    position: fixed;
                    padding: 15px;
                    border-top-right-radius: 6px;
                    border-top-left-radius: 6px;
                    background-color: #ffffff;
                    .btn-cta {
                        width: 100% !important;
                        height: 56px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.choose-lenses--version2 {
    ::v-deep {
        .glasses-flow-header {
            position: fixed;
            top: 0px;
            width: 100%;
        }
    }

    .choose-lenses-mobile-summary {
        ::v-deep {
            .mobile-summary-component {
                position: fixed;
                bottom: 0px;
                width: 100%;
                background-color: #ffffff;
                box-shadow: 0px -1px 24px #DCE3E86E;
                padding: 15px 0px;
            }
        }
    }

    .selection-summary-container {
        width: 44%;
        min-height: 100vh;
        padding: 20px;
        padding-top: 100px;
    }

    .selection-choices-container {
        position: relative;
        width: 56%;
        min-height: 100vh;
        background-color: #F1F8FF;
        padding: 20px;
        padding-left: 95px;
        padding-top: 100px;
    }
}

@media (max-width: $desktopBreakpoint3) {
    .choose-lenses--version2 {
        .selection-choices-container {
            padding-left: 70px;
        }
    }
}

@media (max-width: $breakpoint2) {
    .choose-lenses--version2 {
        .selection-choices-container {
            width: 100%;
            padding: 20px 15px;
            min-height: calc(100vh - 42px);
            padding-bottom: 100px;
        }
    }
}
</style>
