<template>
    <div class="form-label-group">
        <input
            ref="customInput"
            :type="type"
            class="form-control"
            :class="combinedInputClass"
            :value="value"
            @input="onInput"
            :placeholder="label"
            v-mask="mask"
            :maxlength="maxLength"
            :autocomplete="autocomplete"
        >
        <label>{{ label }}</label>
        <div class="invalid-feedback" v-if="errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        maxLength: {
            type: Number,
            defaut: null
        },
        inputClass: {
            type: String,
            default: ''
        },
        mask: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: [String, Number],
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        },
        autocomplete: {
            type: String,
            default: 'on'
        }
    },
    mounted() {
        this.$emit('ref', this.$refs.customInput);
    },
    computed: {
        combinedInputClass() {
            let inputClass = { 'is-invalid': this.errorMessage ? true : false };
            if (this.inputClass) {
                inputClass[this.inputClass] = true;
            }

            return inputClass;
        }
    },
    methods: {
        onInput(e) {
            this.$emit('input', e.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-label-group {
        ::placeholder {
            color: #ffffff !important;
        }
    }
</style>
